import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import Banner from '../../assets/images/cloud-private-banner.png';
import BannerWrap from '../../components/BannerWrap';
import SectionWrap from '../../components/SectionWrap';

import 'swiper/css';
import 'swiper/css/navigation';
import * as styles from './index.module.less';
import Button from '../../components/Button';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import { contactUsModalShow } from '../../components/ContactUsModal';

export const NICE_LIST = [
  {
    title: '分布式架构，扩展无上限',
    desc: '采用分布式架构，支持块、文件、对象和容器统一存储；按需弹性横向扩展，不停机动态扩容到EB级',
  },
  {
    title: '自然散热设计，低功耗',
    desc: '金属导冷散热设计，无需风扇或空调；从架构到底层电路均采用低功耗设计，自研低功耗模式，典型功耗≤35W，节能模式≤18W',
  },

  {
    title: '高可靠',
    desc: '内外结构加固设计，防振，防尘，防霉菌、防潮湿和防盐雾；可在0℃~+50 ℃ 范围稳定工作，适合户外环境部署',
  },
  {
    title: '强实时',
    desc: '国产Linux 实时操作系统，微秒级响应，处理更及时',
  },
  {
    title: '易部署',
    desc: '即插即用，1PB存储30分钟内配置完成，无需专业机房和电力改造',
  },
  {
    title: '自主可控',
    desc: '基础硬件+底层软件均为国产自主研发，整条产品线不受限',
  },
];

export default function CloudPrivatePage() {
  const [machActive, setMachActive] = useState(2);
  return (
    <Layout>
      <BannerWrap
        img={Banner}
        title="土星云分布式存储"
        desc="数字化转型升级和新技术的普遍应用，大数据中心作为数字经济的“底座”，中国已经成为全球最大的能源消耗国，大数据中心“一哄而上”，带来产能过剩风险，大数据中心自身绿色发展的问题，土星云主打低功耗产品，降低数据中心耗能指数，不断深化可持续发展，做出的重要表现与成果。"
      />
      <SectionWrap title="企业级分布式存储" background={'linear-gradient(180deg,#fdfeff,#ebf3ff)'}>
        <div className={styles.machContainer}>
          <div className={styles.machSwiper}>
            <div className={styles.imgViewCard}>
              <img src={`/images/cloud-private-mach-${machActive + 1}.jpg`} alt="" />
            </div>
            <Swiper
              spaceBetween={20}
              slidesPerView={2}
              height={80}
              loop={false}
              navigation
              modules={[Navigation]}
              style={{ padding: '0 40px' }}>
              {Array.from({ length: 2 }).map((item, i) => {
                const index = i + 2;
                let itemClassName = styles.machItem;
                if (index === machActive) {
                  itemClassName += ' ' + styles.machItemActive;
                }
                return (
                  <SwiperSlide key={index} style={{ width: 180 }}>
                    <div
                      className={itemClassName}
                      onClick={() => {
                        setMachActive(index);
                      }}>
                      <img src={`/images/cloud-private-mach-${index + 1}.jpg`} alt="" />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div className={styles.machInfo}>
            <div className={styles.machTitle}>土星云分布式存储</div>
            <div className={styles.machDesc}>
              土星云分布式存储是国科环宇推出的分布式存储产品。该产品同时具备高性能、高可扩展性，高可靠和低能耗等特点，让您轻松应对海量的非结构化数据存储需求，并适应苛刻的野外环境部署。
            </div>
            <div className={styles.machOperate}>
              <a href="/土星云产品文档.docx" download="土星云产品文档.docx">
                <Button type="primary" style={{ marginRight: 20 }}>
                  资料下载
                </Button>
              </a>
              <Button
                type="outline"
                onClick={() => {
                  contactUsModalShow();
                }}>
                购买咨询
              </Button>
            </div>
          </div>
        </div>
      </SectionWrap>
      <SectionWrap title="产品优势" background={'linear-gradient(180deg,#fdfeff,#ebf3ff)'}>
        <div className={styles.niceList}>
          {NICE_LIST.map((item, index) => {
            return (
              <div className={styles.niceItem} key={index}>
                <img
                  className={styles.niceIcon}
                  src={`/images/cloud-private-nice-${index + 1}.png`}
                  alt=""
                />
                <div className={styles.niceTitle}>{item.title}</div>
                <div className={styles.niceDesc}>{item.desc}</div>
              </div>
            );
          })}
        </div>
      </SectionWrap>
      <SectionWrap title="技术规格">
        <table className={styles.normsTable}>
          <thead>
            <tr>
              <th style={{ width: '10em' }}>产品型号</th>
              <th>SCD2-064</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>存储架构</td>
              <td>分布式，支持对象存储/块存储/文件存储</td>
            </tr>
            <tr>
              <td>集群规模</td>
              <td>节点数无上限，容量可达EB级</td>
            </tr>
            <tr>
              <td>存储协议</td>
              <td>S3、CIFS、iSCSI、NFS、Samba、glusterfs</td>
            </tr>
            <tr>
              <td>数据冗余</td>
              <td>纠删码(M+N)、双副本</td>
            </tr>
            <tr>
              <td>数据安全</td>
              <td>传输加密https/tls/ssl、磁盘加密，断电保护</td>
            </tr>
            <tr>
              <td>管理方式</td>
              <td>图形化界面、WEB界面、命令行工具</td>
            </tr>
            <tr>
              <td>存储</td>
              <td>4x 3.5英寸盘位</td>
            </tr>
            <tr>
              <td>网络</td>
              <td>2x 1Gb</td>
            </tr>
            <tr>
              <td>功耗</td>
              <td> {'< 55W'}</td>
            </tr>
            <tr>
              <td>电源</td>
              <td>DC12V±5%</td>
            </tr>
            <tr>
              <td>工作温度</td>
              <td>0℃~+50℃</td>
            </tr>
            <tr>
              <td>外形尺寸</td>
              <td>182mm× 170mm× 193mm（宽高深）</td>
            </tr>
            <tr>
              <td>重量</td>
              <td>{'< 7kg'}</td>
            </tr>
            <tr>
              <td>安装</td>
              <td>机架/非机架</td>
            </tr>
            <tr>
              <td>防护设计</td>
              <td>防振，防尘，防盐雾，防滴露，防霉</td>
            </tr>
            <tr>
              <td>认证</td>
              <td>3C / 节能</td>
            </tr>
          </tbody>
        </table>
      </SectionWrap>
    </Layout>
  );
}

export function Head() {
  return <SEO title="私有云" />;
}
